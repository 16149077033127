import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import '../../styles/curryproducts.css'

export default function KormaHomepage() {
    return (
        <>
            <div className="CurryFirstLayer">
                <Container>
                    <Row>
                        <Col lg="6">
                            <Row>
                                <Col lg="12" className="CurryFirstLayer-Title">
                                    <h2>Korma by Maker Recipe</h2>
                                </Col>
                                {/* <Col lg="12" className="CurryFirstLayer-Subtitle">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed 
                                        do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                        Ut enim ad minim veniam, quis nostrud exercitation ullamco 
                                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute 
                                        irure dolor in reprehenderit in voluptate velit esse cillum 
                                        dolore eu fugiat nulla pariatur. Excepteur sint occaecat 
                                        cupidatat non proident, sunt in culpa qui officia deserunt 
                                        mollit anim id est laborum.
                                    </p>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                </Container>
             </div>
             <div className="CurrySecondLayer">
                <Container>
                    <Row>
                        <Col sm="12" md={{ size: 6, offset: 3 }}>
                            {/* <Link to="/product/chicken-meat-korma-powder"> */}
                                <div className="zoom-curryProduct">
                                    <Row>
                                        <Col md="12">
                                            <StaticImage 
                                                    src="../../images/kormaProducts/Kari_Korma_25gm.png" 
                                                    alt="Curry Product 1"
                                                    width={500}
                                                    height={500} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="curryProductName">
                                            <p>Chicken & Meat Korma Powder</p>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col md="12" className="curryProductDetails">
                                            <p>View Details..</p>
                                        </Col>
                                    </Row> */}
                                </div>
                            {/* </Link> */}
                        </Col>
                    </Row>
                </Container>
             </div>
        </>
        
    )
}


import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/Layout"
import 'bootstrap/dist/css/bootstrap.min.css';
import KormaHomepage from "../../components/products-korma/KormaHomepage";

export default function Home() {
  return (
    <Layout>
      <section>
        <div>
          {/* For page head */}
          <Helmet>
                <title>Maker Recipe Sdn. Bhd.-Korma</title>
          </Helmet>

          <KormaHomepage />
        </div>
      </section>
    </Layout>
    
  )
}